import React from 'react';
import { BsLinkedin, BsGithub, BsFilePdf} from 'react-icons/bs';
import { SiLeetcode } from 'react-icons/si';
import resume from '../assets/resume.pdf';

const SocialMedia = () => (
  <div className="app__social">
    {/* <div>
      <a href="https://www.linkedin.com/in/somtochukwu-oriaku">
        <BsLinkedin />
      </a>
    </div> */}
    {/* <div>
      <a href="https://github.com/somtooriaku/LeetCode-Solutions">
        <SiLeetcode />
      </a>
    </div> */}
    <div>
      <a href="https://github.com/somtooriaku">
        <BsGithub href="https://github.com/somtooriaku" />
      </a>
    </div>
    <div>
      <a href={resume}>
        <BsFilePdf href="https://github.com/somtooriaku" />
      </a>
    </div>
  </div>
);

export default SocialMedia;