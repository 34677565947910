import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './About.scss'
import { urlFor, client } from '../../client';
import { AppWrap, MotionWrap } from '../../wrapper';
import resume from '../../assets/resume.pdf';
import { Tooltip as ReactTooltip } from 'react-tooltip';


const About = () => {
  const [experiences, setExperiences] = useState([]);
  const [abouts, setAbouts] = useState([]);

  useEffect( () => {
    const ExperienceQuery = '*[_type == "experiences"]';
    const query = '*[_type == "abouts"]';

    client.fetch(ExperienceQuery).then((data) => {
      setExperiences(data);
    });

    client.fetch(query).then((data) => {setAbouts(data);})
  }, []);


  return (
    <>
      <div className="head-texts">
        <h2 className="head-text">
          Work <span> Experience </span>  
        </h2>
        <br />
        <p className="pod-text">Welcome to my corner of the digital realm. I am Somtochukwu Oriaku, a distinguished alumnus 
        of the University of Toronto's esteemed Computer Science Specialist Program. My fervor for software engineering is 
        matched only by my insatiable thirst for knowledge in the realms of distributed services, cloud computing and artificial intelligence. 
        With three years of professional experience, I have focused my skills and expertise on crafting impactful solutions and 
        pushing the boundaries of technology. I have furthered my education by acquiring an AWS SAA certification.
        <br /> 
        This platform serves as more detailed extention of my resume.
        <a href={resume}> Resume</a>
        
        </p>
        <div className="app__skills-exp" style={{marginTop:'100px'}}>
          {experiences.map((experience) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text" style={{ fontSize: '1.5rem' }}>{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h3 className="bold-text" style={{ fontSize: '1.7rem', color:'#4c3bcc' }}>{work.name}</h3>
                      <h4 className="bold-text" style={{ fontSize: '1.5rem', color:'#d96421' }}>{work.company}</h4>
                      <p className="p-text" style={{ fontSize: '1.4rem', color:'#5428a1' }}>{work.techStack}</p>
                      <p className="p-text" style={{ fontSize: '1.4rem' }}>{work.desc}</p>
                      <p className="p-text" style={{ fontSize: '1.4rem' }}>{work.desc2}</p>
                      <p className="p-text" style={{ fontSize: '1.4rem' }}>{work.desc3}</p>
                      <p className="p-text" style={{ fontSize: '1.4rem' }}>{work.desc4}</p>
                      <p className="p-text" style={{ fontSize: '1.4rem' }}>{work.desc5}</p>
                      
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
      {/* <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}
      </div> */}
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);