import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: '3hgeeupl',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skcxLWW8oG9i3aunQFGpKGmI9YnAp5mQxQMrQAZYCeON1Gl60sKnzLxAhM2Lq4PTk2lXUwzjeDEQfhmAlUZ23LdnQZ6bvXl9AvloqeXHbRzR5jpsEyCgWjy5WdvNAWZ64kE4ZUk1ZArbkYmyMDHOmKTnfgt7wfT2BiWnu1tl2pkGkSjsyHCz',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

// process.env.REACT_APP_SANITY_TOKEN
// skcxLWW8oG9i3aunQFGpKGmI9YnAp5mQxQMrQAZYCeON1Gl60sKnzLxAhM2Lq4PTk2lXUwzjeDEQfhmAlUZ23LdnQZ6bvXl9AvloqeXHbRzR5jpsEyCgWjy5WdvNAWZ64kE4ZUk1ZArbkYmyMDHOmKTnfgt7wfT2BiWnu1tl2pkGkSjsyHCz