import React from 'react'
import './Header.scss'
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap } from '../../wrapper';
import Typist from "react-typist"


const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  return (
    <div className="app__header app__flex" >
      <motion.div 
      whileInView={{ x:[-100, 0], opacity: [0, 1]}}
      transition={{ duration: 5 }}
      className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
          <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello there, I'm </p>
              <h1 className="head-text">Somto Oriaku</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
          
            <span className="intro-title">
                
            </span>
            
            <Typist avgTypingDelay={100} cursor={{ show:false } }>
            <p className="p-text">3+ years as a software developer </p>
            <p className="p-text"> University of Toronto Computer Science Specialist Graduate </p>
            <p className="p-text">SAA AWS certification </p>
            </Typist>
          </div>
          
        </div>
      </motion.div>
      
      <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5}}
      className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
        <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
        />
      </motion.div>

      <motion.div 
      whileInView={{ x:[-500, 0], opacity: [0, 1]}}
      transition={{ duration: 3 }}
      className="app__header-info"
      >
        <div className="app__header-badge">
          
          <div className="tag-cmp app__flex">
            <p className="p-text"> I'm a software engineer residing in Toronto, Canada. I've spearheaded and 
            participated in crafting top-tier, large-scale, functional products, and played a pivotal role in 
            launching significant features for technological services. Additionally, I've contributed to a company
            boasting over 94 million monthly users. </p>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home');